<template>
  <div :class="['team_member', right ? 'right': 'left']">
    <div class="content">
      <div class="img_wrapper">
        <img v-bind:src="teamMember.icon" alt="" draggable="false">
      </div>
      <h3>{{ teamMember.name || 'Name Surname' }}</h3>
      <h4>{{ teamMember.title }}</h4>
      <p>{{ teamMember.description }}</p>
    </div>
  </div>
</template>

<script>
import HandleScrollMixin from "@/mixins/HandleScrollMixin";

export default {
  name: "TeamMember",
  mixins: [HandleScrollMixin],
  props: {
    right: {
      type: Boolean,
      default: false
    },
    teamMember: {
      type: Object,
      required: true
    }
  },
  methods: {
    onScroll: function () {
      this.handleScroll('.team_member')
    }
  },
  created() {
    window.addEventListener('scroll', this.onScroll);
  },
  destroyed() {
    window.removeEventListener('scroll', this.onScroll);
  },
}
</script>

<style lang="scss" scoped>

$color1: #003871FF;
$color2: #d0d4dd;


.team_member {
  transform: translateX(-15vmin);
  opacity: 0;
  transition: 1s all ease;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  p {
    color: var(--texts_color);
    font-style: italic;
  }

  .content {
    margin-top: 5.5vmin;
    width: calc(100% - 33vmin);
    max-width: 50vw;

    &::before {
      content: "";
      width: 100%;
      background: $color2;
      height: 2.5vmin;
      display: block;
      transform: translateX(-14vmin)
    }
    h3 {
      color: var(--color5);
      opacity: 0.9;
      text-transform: uppercase;
      margin-top: 2.5vmin;
      margin-bottom: 0.5em;
    }
    h4 {
      color: var(--color2);
      font-weight: 400;
      text-transform: capitalize;
      margin-top: 0.2vmin;
      white-space: pre-line;
      margin-bottom: 1.5em;
    }
    p {
      margin: 1vmin auto;
    }

    .img_wrapper {
      width: 28vmin;
      height: 28vmin;
      border-radius: 50%;
      border: 0.7vmin solid $color2;
      right: 0;
      overflow: hidden;
      transform: translateY(-2.6vmin);
      background-color: $color2;
      display: inline;
      float: right;
      margin: 0 0.5em;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  &.right {
    align-items: flex-end;
    transform: translateX(15vmin);

    .img_wrapper {
      float: left;
    }

    .content {
      text-align: right;
      &::before {
        transform: translateX(14vmin)
      }
    }
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    .content {
      min-width: 85vw;
      &::before {
        transform: translateX(-4.5rem)
      }
      .img_wrapper {
        width: 9rem;
        height: 9rem;
      }
    }

    &.right {
      .content::before {
        transform: translateX(4.5rem)
      }
    }
  }

  @media (max-width: 767.98px) {
    .content {
      h4 {
        line-height: 1.3em;
      }
    }
  }

  // X-Small devices (portrait phones, less than 576px)
  @media (max-width: 576.98px) {
    .content {
      .img_wrapper {
        width: 7.5rem;
        height: 7.5rem;
      }
      h3{
        font-size: 1.1rem;
      }

      h4{
        font-size: 1rem;
      }
    }
  }

  &.active {
    transform: translateY(0);
    opacity: 1;
  }
}

</style>