<template>
    <div class="container_salyut">
      <div class="under-glow"></div>
      <div class="globe">
        <img class="logo" :src="img" draggable="false">
      </div>
      <div class="flare"></div>
    </div>
</template>

<script>
  export default {
    data() {
      return {
        img:require('../../assets/logo.png')
      }
    }
  }
</script>
<style scoped>

:root {
  --borders: 0.1em solid whitesmoke;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.logo {
  max-height: 80%;
  display: block;
  padding: 1.6vmin;
  opacity: 0.7;
  animation: 2s light infinite ease-in-out;
}


::before,
::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
}

.container_salyut {
  height: 22vmin;
  width: 22vmin;
  position: relative;
  animation: 5s linear infinite bob;
}

.container_salyut * {
  border-radius: 50%;
  position: absolute;
  transform-origin: center center;
}

.container_salyut::before {
  top: 5.95em;
  height: 0.1em;
  width: 20vmin;
  border-top: var(--borders);
  z-index: 2;
}

.container_salyut::after {
  left: 5.95em;
  height: 20vmin;
  width: 0.1em;
  border-right: var(--borders);
  z-index: 2;
}

.globe {
  height: 20vmin;
  width: 20vmin;
  background-color: transparent;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}


.under-glow {
  height: 20vmin;
  width: 20vmin;
  background: linear-gradient(
      105deg,
      rgba(70, 130, 180, 1) 25%,
      rgba(9, 55, 117, 1) 80%,
      rgba(37, 58, 86, 1)
  );
  box-shadow: -0.2em 0 0.5em 0.3em whitesmoke;
  animation: 15s linear infinite color-wheel;
}

.flare {
  background-color: white;
  box-shadow: 0 0 1vmin 0.5vmin white;
  position: absolute;
  top: 13%;
  left: 9%;
  opacity: 0;
  animation: 15s linear infinite flare;
  z-index: 2;
}

.inner,
.outer,
::after,
::before {
  animation: 15s linear infinite reverse color-borders;
}

@keyframes flare {
  from {
    transform: rotate(0deg) scaleX(1);
    opacity: 0;
  }
  5% {
    transform: rotate(720deg) scaleX(20);
    opacity: 1;
  }
  10% {
    transform: rotate(1080deg) scaleX(1);
    opacity: 0;
  }
}

@keyframes bob {
  from {
    transform: translateY(-0.3em);
  }
  25% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(0.3em);
  }
  75% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-0.3em);
  }
}

@keyframes color-borders {
  from {
    border-color: white;
  }
  20% {
    border-color: skyblue;
  }
  40% {
    border-color:  white;
  }
  60% {
    border-color: white;
  }
  80% {
    border-color: skyblue;
  }
  100% {
    border-color: white;
  }
}

@keyframes color-wheel {
  from {
    transform: rotate(0deg);
    box-shadow: -0.7em 0 1.5em  white;
  }
  20% {
    transform: rotate(72deg);
    box-shadow: -0.2em -0.1em 1em 0.3em rgba(135, 206, 235, 0.59);
  }
  40% {
    transform: rotate(144deg);
    box-shadow: -0.2em -0.2em 1em 0.3em white;
  }
  60% {
    transform: rotate(216deg);
    box-shadow: -0.2em -0.2em 1em 0.3em white;
  }
  80% {
    transform: rotate(288deg);
    box-shadow: -0.2em -0.1em 1em 0.3em rgba(135, 206, 235, 0.61);
  }
  100% {
    transform: rotate(360deg);
    box-shadow: -0.7em 0 1.5em 0.7em white;
  }
}

@keyframes light {
  from {
    opacity: 0.7;
  }

  50% {
    opacity: 0.9;

  }
  to {
    opacity: 0.7;
  }
}

@media (max-width: 991.98px) {
    .container_salyut {
        transform: scale(1.8) translateY(15%) !important; ;
    }
}

@media (max-width: 767.98px) {
    .container_salyut {
        transform: scale(2.7) translateY(5%) translateX(5%) !important; ;
    }
}
</style>

