<template>
  <section class="team_content">
    <div class="container">
      <main-heading-block>
        <h2>{{ $t('our_team_content_head.title') }}</h2>
        <p>{{ $t('our_team_content_head.description') }}</p>
      </main-heading-block>
      <div class="team_content_blocs">
        <div>
          <team-member
              class="team_member"
              v-for="(teamMember, key) in this.$i18n.messages[this.$i18n.locale].our_team_content_bloc_items"
              :key="`team_member-${key}`"
              :team-member=teamMember
              :right="key%2!==0"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MainHeadingBlock from "@/components/blocks/MainHeadingBlock";
import TeamMember from "@/components/team_component/TeamMember";

export default {
  components: {TeamMember, MainHeadingBlock},
};
</script>

<style lang="scss" scoped>
.team_content {
  margin: 0 auto;
  overflow: hidden;
  background-image: url('../../assets/svg/teambg.svg');
  background-size: contain;
  background-position-y: 28%;
  background-repeat: repeat-y;
  @media (max-width: 991.98px) {
    background-size: 250%;
  }
}

.team_content_blocs {
  padding-top: 2vmin;
}

.team_member {
  margin-top: 3vmin;

  // X-Large devices (large desktops, less than 1400px)
  @media (max-width: 1399.98px) {
    margin-top: 5rem;
  }

  // Medium devices (tablets, less than 992px)
  @media (max-width: 991.98px) {
    margin-top: 7rem;
  }

  // Small devices (landscape phones, less than 768px)
  @media (max-width: 767.98px) {
    margin-top: 4rem;
  }
}

</style>
