<template>
  <div class="home">
    <Header head_text="team_page" content_text="team_content_text" page="team_page">
      <template v-slot:animation>
        <Animation />
      </template>
    </Header>
    <OurTeam />
    <Footer/>
  </div>
</template>

<script>
import Header from '@/components/Header_.vue'
import Animation from '@/components/team_component/Animation.vue';
import OurTeam from '@/components/team_component/Our_team.vue';
import Footer from '@/components/Footer.vue';

export default {
  name: 'Home',
  components: {
    Header,OurTeam,Footer,Animation
  },
  data () {
    return{
    }
  } 
}
</script>
<style scoped>

</style>
